import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

function App() {
    const [email, setEmail] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [result, setResult] = useState(null);
    const [error, setError] = useState('');

    // Basic Auth credentials
    const BASIC_AUTH_USERNAME = 'jsimao';//process.env.BASIC_AUTH_USERNAME; Replace with your username
    const BASIC_AUTH_PASSWORD = '123456';//process.env.BASIC_AUTH_PASSWORD; Replace with your password

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setResult(null);

        try {
            const response = await axios.post(
                'https://secretsanta-api.simaohomelab.online/api/secretsanta/participants/getRecieverInfo',
                { email, secretKey },
                {
                    timeout: 10000, // 10 seconds timeout
                    auth: {
                        username: BASIC_AUTH_USERNAME,
                        password: BASIC_AUTH_PASSWORD,
                    },
                }
            );
            setResult(response.data);
        } catch (err) {
            if (err.code === 'ECONNABORTED') {
                setError('Request timed out. Please try again later.');
            } else if (err.response) {
                // Server responded with a status other than 2xx
                setError(`Error: ${err.response.status} - ${err.response.data.message || 'Something went wrong.'}`);
            } else {
                setError('Failed to fetch data. Please check your connection and try again.');
            }
        }
    };

    return (
        <div className="app-container">
            <header className="header">
                <h1>🎅 Pai Natal Secreto</h1>
                <p>Descobre o teu match!</p>
            </header>
            <div className="form-container">
                <form onSubmit={handleSubmit} className="form">
                    <input
                        type="email"
                        placeholder="Introduz o teu email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Introduz a secret key"
                        value={secretKey}
                        onChange={(e) => setSecretKey(e.target.value)}
                        required
                    />
                    <button type="submit">Descobre o teu amigo secreto</button>
                </form>
                {error && <div className="error-message">{error}</div>}
                {result && (
                    <div className="result-container">
                        <h2>O teu amigo secreto</h2>
                        <p><strong>O</strong> {result.giver}</p>
                        <p><strong>Vai dar o Presente a</strong> {result.reciever}</p>
                    </div>
                )}
            </div>
            <footer className="footer">
                <img
                    src='santa.webp'
                    alt="Santa Claus"
                    className="santa-image"
                />
                <img
                    src="christmastree.webp"
                    alt="Christmas Tree"
                    className="tree-image"
                />
                <img
                    src="present.webp"
                    alt="Present"
                    className="present-image"
                />
            </footer>
        </div>
    );
}

export default App;
